<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__dept">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <el-button
            v-if="$hasPermission('sys:dept:save')"
            type="primary"
            @click="addOrUpdateHandle()"
            >{{ $t('add') }}</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        row-key="id"
        border
        style="width: 100%"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        >
        <el-table-column
          prop="name"
          :label="$t('dept.name')"
          header-align="center"
          min-width="150"
        ></el-table-column>

        <el-table-column
          prop="orgIdentify"
          label="企业码"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <div v-show="scope.row.orgIdentify">
              {{ scope.row.orgIdentify }}
            </div>
            <el-button
              v-show="!scope.row.orgIdentify"
              v-if="scope.row.pid === '0'"
              type="text"
              size="small"
              @click="addCode(scope.row)"
              >生成企业码</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="parentName"
          :label="$t('dept.parentName')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sort"
          :label="$t('dept.sort')"
          header-align="center"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              v-if="$hasPermission('sys:dept:update')"
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
              >{{ $t('update') }}</el-button
            >
            <el-button
              v-if="$hasPermission('sys:dept:delete')"
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id)"
              >{{ $t('delete') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update
        v-if="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getDataList"
      ></add-or-update>
      <!-- 选择地区 -->
      <arg-choose-area
        v-if="chooseAreaVisible"
        ref="argChooseArea"
        @refreshDataList="getDataList"
      ></arg-choose-area>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import AddOrUpdate from './dept-add-or-update';
import ArgChooseArea from './arg-choose-area';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/org/list',
        deleteURL: '/org/dept',
      },
      chooseAreaVisible: false,
    };
  },
  created() {},
  components: {
    AddOrUpdate,
    ArgChooseArea,
  },
  methods: {
    addCode(row) {
      this.chooseAreaVisible = true;
      console.log(this.$refs.ArgChooseArea);
      this.$nextTick(() => {
        this.$refs.argChooseArea.init(row);
      });
    },
  },
};
</script>
